import React from "react"
import ReactDOM from "react-dom"

import initApp, { AppOptions } from "./init"
import App from "./App"

initApp().then((appOptions: AppOptions) => {
    ReactDOM.render(
        <React.StrictMode>
            <App
                appOptions={appOptions}
            />
        </React.StrictMode>,
        document.getElementById("root"),
    )
})
