import React from "react"
import PropTypes from "prop-types"
import {
    Spring,
    animated,
    Keyframes,
} from "react-spring/renderprops"

import anime from "animejs"
import delay from "delay"

import { ReactComponent as Logo } from "assets/images/logo.svg"

import Container from "./style"

export interface Props {
    isLoaded?: boolean;
    style?: React.CSSProperties;
    refProps?: React.RefObject<HTMLDivElement>;
    className?: string;
    id?: string;
}

export interface State {
}

const Script: any = Keyframes.Spring(async (next: (props: any) => void) => {
        await next({
            transform: `rotate(${anime.random(-100, 100)}deg)`,
            config: {
                mass: 1,
                tension: 170,
                friction: 30,
            },
        })
        while (true) {
            next({
                transform: `rotate(${anime.random(-100, 100)}deg)`,
                config: {
                    mass: 51,
                    tension: 170,
                    friction: 30,
                },
            })
            await delay(5000)
        }
    },
)

const AnimatedContainer = animated(Container)

class Spinner extends React.PureComponent<Props, State> {
    static propTypes = {
        isLoaded: PropTypes.bool,
        style: PropTypes.object,
        refProps: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.any }),
        ]),
        className: PropTypes.string,
        id: PropTypes.string,
    }

    static defaultProps = {
        className: "",
        isLoaded: false,
    }

    items: number[] = new Array(5).fill(1)

    renderCircle (item: number, index: number): React.ReactNode {
        return (
            <div
                key={`${index}`}
                className="circle"
                style={{
                    transform: `rotate(${(index + 1) * 70}deg)`,
                }}
            >
                <div
                    className="circle-inner"
                    style={{
                        animation: `spin ${index}s infinite linear`,
                    }}
                >
                </div>
            </div>
        )
    }

    render (): React.ReactNode {
        const {
            id,
            className,
            refProps,
            style,
            isLoaded,
        } = this.props

        return (
            <div>
                <Spring
                    native={true}
                    to={{
                        percentage: isLoaded
                            ? 100
                            : 0,
                        opacity: isLoaded
                            ? 1
                            : 0,
                    }}
                >
                    {
                        ({ percentage, opacity }: { percentage: any, opacity: any }) => {
                            const transform = percentage.interpolate((value: number) => {
                                const translateX = value / 2
                                const translateY = value - 50
                                const scale = (200 - value) / 100
                                return `translate3d(calc(-${translateX}vw + ${translateY}%), calc(-${translateX}vh + ${translateY}%), 0) scale(${scale})`
                            })

                            return (
                                <AnimatedContainer
                                    id={id}
                                    className={`${className} ${isLoaded ? "logo" : ""}`}
                                    ref={refProps}
                                    style={{
                                        ...style,
                                        transform,
                                    }}
                                    href={isLoaded ? "/" : undefined}
                                >
                                    <figure>
                                        {
                                            this.items.map(this.renderCircle)
                                        }
                                    </figure>
                                    <Script
                                        native={true}
                                    >
                                        {
                                            (props: any) => (
                                                <animated.figure
                                                    style={{
                                                        ...props,
                                                        opacity,
                                                    }}
                                                    className="logo-text"
                                                >
                                                    <Logo
                                                        className="text"
                                                    />
                                                </animated.figure>
                                            )
                                        }
                                    </Script>
                                </AnimatedContainer>
                            )
                        }
                    }
                </Spring>
            </div>
        )
    }
}

export default Spinner
