import styled from "styled-components"
import "assets/css/reset.css"
import "assets/css/font.css"

const container = styled.main`
    & {
        background-color: ${({ theme }) => theme.bg_color};
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    *::selection {
        color: ${({ theme }) => theme.title_text_color};
        background-color: ${({ theme }) => theme.primary_color};
        caret-color: ${({ theme }) => theme.title_text_color};
        outline: ${({ theme }) => theme.primary_color};
    }

    .error,
    .error * {
        color: ${({ theme }) => theme.error_color} !important;
    }

    .error .placeholder::after {
        content: "*";
    }

    .disabled,
    .disabled * {
        opacity: var(--disabled_opacty) !important;
        cursor: not-allowed !important;
    }

    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    *,
    *:focus {
        outline: none;
    }

    // -------------------- CONTSTANT ------------------------
    & {
        --hover_opacity: 0.8;
        --focus_opacity: 0.6;
        --disabled_opacty: 0.3;
        --placeholder_opacty: 0.5;

        --text_margin: 1px;

        --image_spinner_size: 36px;

        --drawer_size: 596px;
        --modal_width: 960px;
        --modal_heigh: 600px;

        --max_width: 1920px;

        --text_ss: 8px;
        --text_s: 12px;
        --text_m: 16px;
        --text_l: 20px;
        --text_xl: 28px;
        --text_xxl: 32px;
        --text_xxxl: 56px;

        --line_ss: 12px;
        --line_s: 16px;
        --line_m: 20px;
        --line_l: 24px;
        --line_xl: 32px;
        --line_xxl: 36px;
        --line_xxxl: 60px;
    }

    @media only screen and (max-width: 720px) {
        & {
            --text_ss: 6px;
            --text_s: 8px;
            --text_m: 12px;
            --text_l: 16px;
            --text_xl: 20px;
            --text_xxl: 28px;
            --text_xxxl: 64px;

            --line_ss: 10px;
            --line_s: 12px;
            --line_m: 16px;
            --line_l: 20px;
            --line_xl: 24px;
            --line_xxl: 32px;
            --line_xxxl: 68px;
        }
    }

    @media only screen and (max-width: 480px) {
        & {
            --text_ss: 6px;
            --text_s: 8px;
            --text_m: 12px;
            --text_l: 16px;
            --text_xl: 20px;
            --text_xxl: 28px;
            --text_xxxl: 44px;

            --line_ss: 10px;
            --line_s: 12px;
            --line_m: 16px;
            --line_l: 20px;
            --line_xl: 24px;
            --line_xxl: 32px;
            --line_xxxl: 48px;
        }
    }

    // -------------------- KEYFRAMES ------------------------
    @keyframes fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    @keyframes slide-in {
        from {
            transform: translateX(-100%);
        }
        to {
            transform: translateX(0%);
        }
    }

    @keyframes slide-out {
        from {
            transform: translateX(0%);
        }
        to {
            transform: translateX(100%);
        }
    }

    @keyframes rolling {
        0% {
            transform: rotateZ(0deg);
            color: ${({ theme }) => theme.title_text_color};
        }
        20% {
            transform: rotateZ(90deg);
            color: ${({ theme }) => theme.primary_color};
        }
        25% {
            transform: rotateZ(90deg);
            color: ${({ theme }) => theme.title_text_color};
        }
        45% {
            transform: rotateZ(180deg);
            color: ${({ theme }) => theme.primary_color};
        }
        50% {
            transform: rotateZ(180deg);
            color: ${({ theme }) => theme.title_text_color};
        }
        70% {
            transform: rotateZ(270deg);
            color: ${({ theme }) => theme.primary_color};
        }
        75% {
            transform: rotateZ(270deg);
            color: ${({ theme }) => theme.title_text_color};
        }
        95% {
            transform: rotateZ(360deg);
            color: ${({ theme }) => theme.primary_color};
        }
        100% {
            transform: rotateZ(360deg);
            color: ${({ theme }) => theme.title_text_color};
        }
    }

    @keyframes glow {
        from {
            text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073,
                0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073,
                0 0 70px #e60073;
        }
        to {
            text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6,
                0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6,
                0 0 80px #ff4da6;
        }
    }
`

export default container
