import translationEN from "config/locales/en"
import translationTH from "config/locales/th"

const resources = {
    en: translationEN,
    th: translationTH,
}

export default {
    resources: resources,
    whitelist: ["en", "th"],
    lng: "th",
    fallbackLng: "th",
    cleanCode: true,
    objectNotation: true,
    saveMissing: true,
    returnNull: false,
    returnEmptyString: false,
    backend: {
        loadPath: "./config/locales/en/test.json",
    },
    react: {
        useSuspense: false,
    },
}
