import styled from "styled-components"

const container = styled.a`
    --size: 50px;
    & {
        border-radius: 100%;
        position: fixed;
        top: 50vh;
        left: 50vw;
        width: var(--size);
        height: var(--size);
        padding: 0;
        margin: 0;
        transform: translate3d(calc(-50vw - -50%), calc(-50vh - -50%), 0) scale(2, 2);
        cursor: auto;
        transition: opacity 400ms;
    }

    & > figure:first-child {
        width: 100%;
        height: 100%;
        animation: spin 5s infinite linear;
    }

    & > figure.logo-text {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        box-sizing: border-box;
        overflow: visible;
    }

    & > figure.logo-text > svg {
        fill: ${({ theme }) => theme.primary_color}EF;
        width: 100%;
        height: 100%;
        padding: 12px;
        box-sizing: border-box;
    }

    & > p.loading-text {
        font-size: var(--text_s);
        line-height: var(--line_s);
        font-weight: 400;
        text-align: center;
        color: ${({ theme }) => theme.title_text_color};
        margin: auto;
        padding: 12px 0;
    }

    &.logo:hover {
        opacity: var(--hover_opacity);
        cursor: pointer;
    }

    &.logo:focus {
        opacity: var(--focus_opacity);
        cursor: pointer;
    }

    &:link,
    &:visited {
        text-decoration: none;
    }

    .circle {
        width: 100%;
        height: 100%;
        position: absolute;
    }

    .circle-inner {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        border: 2.5px solid ${({ theme }) => `${theme.primary_color}B3`};
        border-right: none;
        border-top: none;
        background-clip: padding;
        box-shadow: inset 0px 0px 5px ${({ theme }) => `${theme.primary_color}2C`};
    }

    .text path {
    }

    @media (max-width: 767px) {
        & {
            --size: 42px;
        }
    }
`

export default container
