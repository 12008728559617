import React from "react"
import { ThemeProvider } from "styled-components"
import theme from "config/theme.json"
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
} from "react-router-dom"

import Container from "globalStyle"

import Spinner from "components/atoms/Spinner"

import siteConfig from "config/site.json"

const Main = React.lazy(() => import("containers/pages/Main"))

import { AppOptions } from "init"

interface Props {
    appOptions: AppOptions;
}

class App extends React.PureComponent<Props, unknown> {
    render (): React.ReactNode {
        return (
            <ThemeProvider
                theme={theme}
            >
                <Container>
                    <BrowserRouter>
                        <Switch>
                            <Route
                                publicRoute={true}
                                exact={true}
                                path={siteConfig.routes.all}
                            >
                                <React.Suspense fallback={<Spinner />}>
                                    <Main />
                                </React.Suspense>
                            </Route>
                            <Redirect to={siteConfig.routes.home} />
                        </Switch>
                    </BrowserRouter>
                </Container>
            </ThemeProvider>
        )
    }
}

export default App
