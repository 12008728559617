import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import i18nConfig from "config/i18n"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
// import reportWebVitals from "./reportWebVitals"

import anime from "animejs"

(anime as any).suspendWhenDocumentHidden = false

declare global {
    export interface String {
        format: (...args: any[]) => string;
    }

    export interface Number {
        clamp: (min: number, max: number) => number;
    }
}

String.prototype.format = function format (...args: any[]) {
    return this.replace(/{(\d+)}/g, (match, number) => {
        return typeof args[number] !== "undefined"
            ? args[number]
            : match
    })
}

Number.prototype.clamp = function clamp (min, max) {
  return Math.min(Math.max(this.valueOf(), min), max)
}

i18n.use(initReactI18next).init(i18nConfig)

export interface AppOptions {
    "store": any;
}

async function initApp (): Promise<AppOptions> {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.unregister()

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    // reportWebVitals()

    return {
        "store": null,
    }
}

export default initApp
